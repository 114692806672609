.top-access {
  margin-right: auto;
  background-color: #f5f1ee;
  @include mq(u-tab) {
    padding: 80px 40px 0;
  }
  @include mq(u-sp) {
    padding: 80px 15px 0;
  }
}

.top-access__list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;
}

.top-access__list__item {
  padding-left: 32px;
  position: relative;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.05em;
  &:before {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    top: 2px;
    left: 0;
    background-size: contain;
  }
  & > ul > li {
    padding-left: 24px;
    position: relative;
    &::after {
      position: absolute;
      content: "・";
      font-weight: bold;
      font-size: 16px;
      line-height: 28.8px;
      left: 0;
      top: 0;
    }
  }
  & > a {
    color: #d68622;
    &[data-type="external"] {
      padding-right: 20px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%2216%22%20viewBox%3D%220%200%2017%2016%22%20width%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.33333%22%3E%3Cpath%20d%3D%22m7.8335%208.66667%206.6667-6.66667m0%200h-4m4%200v4%22%20stroke%3D%22%23d68622%22%2F%3E%3Cpath%20d%3D%22m14.5%2010v2c0%201.1046-.8954%202-2%202h-8c-1.10457%200-2-.8954-2-2v-8c0-1.10457.89543-2%202-2h2%22%20stroke%3D%22%232d2314%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
        background-size: cover;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
  &[data-type="train"]::before {
    background-image: url("../img/top/img_icon_train.png");
  }
  &[data-type="car"]::before {
    background-image: url("../img/top/img_icon_car.png");
  }
  &[data-type="pin"]::before {
    background-image: url("../img/top/img_icon_pin.png");
  }

  @include mq(u-sp) {
    font-size: 14px;
  }
}
