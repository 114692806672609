.footer {
  padding-top: 100px;
  background-color: #f5f1ee;
  @include mq(u-sp) {
    padding-top: 80px;
  }
}

.footer__illust {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 40px;
    left: 0;
    bottom: 0;
    background-color: #866653;
    box-shadow: 0px 30px 20px rgba(0, 0, 0, 0.4);
    @include mq(u-sp) {
      height: 20px;
    }
  }
}

.footer__illust__image {
  position: relative;
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
  z-index: 2;
  padding-bottom: 14px;
  @include mq(u-sp) {
    padding-bottom: 8px;
  }
}

.footer__inner {
  background: #2d2314;
  padding: 140px 40px 120px;
  position: relative;
  z-index: 2;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url("../img/texture/img_texture_footer_wanted.png");
    background-size: 200px;
    background-repeat: repeat;
    opacity: 1;
    transition: 0.2s;
    opacity: 0.5;
  }
  @include mq(u-tab) {
    padding: 140px 40px;
  }
  @include mq(u-sp) {
    padding: 60px 40px 80px;
  }
}

.footer-content {
  display: flex;
  max-width: 984px;
  margin: 0 auto;
  @include mq(u-tab) {
    flex-direction: column;
    gap: 60px;
    margin: 0;
  }
}

.footer__info__logo {
  width: 257px;
  display: inline-block;
  @include mq(u-sp) {
    width: 193px;
  }
}

.footer__info__copy {
  margin-top: 20px;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #b8a396;
  @include mq(u-sp) {
    margin-top: 16px;
  }
}

.footer__info__sns {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 40px;
  & > p {
    font-family: "montserrat";
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #fffffa;
  }
}

.footer__sns__list {
  display: flex;
  align-items: center;
  gap: 24px;
}

.footer__sns__list__item {
  width: 20px;
  display: inline;
  & a {
    display: flex;
    align-items: center;
  }
}

.footer__nav {
  margin-left: auto;
  display: flex;
  gap: 120px;
  @include mq(u-tab) {
    margin-left: unset;
  }
}

.footer__link__list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  @include mq(u-tab) {
    gap: 48px;
  }
}

.footer__link__list__item {
  font-family: "montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.12em;
  color: #fffffa;
  width: 120px;
  & > a {
    position: relative;
    transition: all 0.2s;
    &:before {
      position: absolute;
      content: "・";
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.12em;
      color: #d68622;
      left: 0;
      opacity: 0;
    }
  }
  &:hover > a {
    padding-left: 20px;
    &:before {
      opacity: 1;
    }
  }
}
