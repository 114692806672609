// animation-name: ; //keyframes
// animation-duration: ; //duration
// animation-timing-function: ; //easing
// animation-delay: ; //delay
// animation-iteration-count: ; // 1,3,infinite
// animation-direction: ; //normal,reverse,alternate,alternate-reverse
// animation-fill-mode: ; //none,forwards,backwards,both
// animation-play-state: ; //running,paused (animationを止める)

@keyframes fadein-top {
  0% {
    height: 0;
  }
  100% {
    height: 124px;
  }
}

@keyframes fadein-top-sp {
  0% {
    height: 0;
  }
  100% {
    height: 104px;
  }
}

@keyframes fadein-bottom {
  0% {
    height: 0;
  }
  100% {
    height: 40px;
  }
}

@keyframes fadein-left-and-right {
  0% {
    width: 0;
  }
  100% {
    width: calc((100vw - 70%) / 2);
  }
}
@keyframes fadein-left-and-right-tab {
  0% {
    width: 0;
  }
  100% {
    width: 40px;
  }
}
@keyframes fadein-left-and-right-sp {
  0% {
    width: 0;
  }
  100% {
    width: 35px;
  }
}

@keyframes circle {
  0% {
    stroke-dasharray: 0 628;
  }
  99.9%,
  to {
    stroke-dasharray: 628 628;
  }
}

@keyframes sendline {
  0% {
    stroke-dasharray: 0 507;
  }
  100% {
    stroke-dasharray: 507 507;
  }
}

@keyframes sendlineStatic {
  0% {
    stroke-dasharray: 507 507;
  }
}
