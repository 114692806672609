.loading-insert div,
.loading-insert--late div {
  content: "";
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100%;
  pointer-events: none;
}

.loading-insert {
  z-index: 9999;
  & > div {
    transition: 0.4s;
    background-color: #b8a396;
    z-index: 9999;
  }
  &.is-month {
    display: none;
  }
}

.loading-insert--late {
  z-index: 9998;
  & > div {
    transition: 0.4s;
    z-index: 1;
    z-index: 9998;
    &:first-child {
      background: #f5f1ee;
      opacity: 0.8;
      transition-delay: 0.2s;
    }
    &:last-child {
      background: #f5f1ee;
      opacity: 0.8;
      transition-delay: 0.3s;
    }
  }
  &.is-month {
    display: none;
  }
}

body.is-loading .loading-insert > div {
  left: 0;
}

body.is-loading .loading-insert--late > div {
  left: 0;
}
