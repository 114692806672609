.top-fv.is-loading {
  .top-fv {
    height: 100vh;
  }
  .top-fv__inner {
    height: 100vh;
    width: 100vw;
    max-width: unset;
    border: unset;
    aspect-ratio: unset;
  }
}
.top-fv {
  height: 100vh;
  background-color: #f5f1ee;
}
.top-fv__inner {
  height: 100vh;
  margin: 0 auto;
  background: linear-gradient(
    179.94deg,
    #e2af89 0.05%,
    rgba(142, 88, 48, 0) 114.75%
  );
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: url("../img/texture/img_texture_fv.png");
    background-size: 200px;
    background-repeat: repeat;
    opacity: 1;
    transition: 0.2s;
    opacity: 0.5;
  }
}

.is-loaded .top-fv__inner {
  height: 100%;
}

.top-fv__inner__border__top,
.top-fv__inner__border__left,
.top-fv__inner__border__right,
.top-fv__inner__border__bottom {
  position: absolute;
  background-color: #f5f1ee;
  animation-duration: 1s;
  animation-delay: 0s;
  z-index: 3;
}

.is-loaded .top-fv__inner__border__top {
  animation-name: fadein-top;
  animation-fill-mode: forwards;
  @include mq(u-sp) {
    animation-name: fadein-top-sp;
  }
}

.is-loaded .top-fv__inner__border__bottom {
  animation-name: fadein-bottom;
  animation-fill-mode: forwards;
}

.is-loaded .top-fv__inner__border__left,
.is-loaded .top-fv__inner__border__right {
  animation-name: fadein-left-and-right;
  animation-fill-mode: forwards;
  @include mq(u-tab) {
    animation-name: fadein-left-and-right-tab;
  }
  @include mq(u-sp) {
    animation-name: fadein-left-and-right-sp;
  }
}

.top-fv__inner__border__top {
  width: 100%;
  height: 0px;
  top: 0;
  overflow: hidden;
  & .top-fv__inner__border__top__logo {
    margin: 40px auto 0;
    display: none;
    position: relative;
    width: 257px;
    // opacity: 0;
    @include mq(u-tab) {
      width: 196px;
    }
  }
}
.top-fv__inner__border__left {
  width: 0px;
  height: 100%;
  left: 0;
}
.top-fv__inner__border__right {
  width: 0px;
  height: 100%;
  right: 0;
  overflow: hidden;
}
.top-fv__inner__border__bottom {
  width: 100%;
  height: 0px;
  bottom: 0;
}

.top-fv__inner__img {
  transition: opacity 1.6s;
  position: absolute;
  width: calc(100vw - ((100vw - 70%) / 2));
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  opacity: 1;
  @include mq(u-tab) {
    width: 100%;
    overflow: hidden;
    padding-top: 220px;
    top: 53%;
    & > img {
      width: 1570px;
      transform: translateX(-17%);
    }
  }
  @include mq(u-sp) {
    width: 100%;
    overflow: hidden;
    padding-top: 70px;
    top: 52%;
    & > img {
      width: 990px;
      transform: translateX(-17%);
    }
  }
}

.top-fv__inner__heading {
  position: absolute;
  width: 21%;
  top: 0;
  left: 28px;
  transform: translateY(-50%);
  @include mq(u-tab) {
    top: 150px;
    left: 15px;
    width: 415px;
  }
  @include mq(u-sp) {
    top: 70px;
    left: 15px;
    width: 198px;
  }
}

.is-hidden {
  opacity: 0;
}

.top-fv__menu {
  position: absolute;
  top: 50%;
  right: 4.2vw;
  transform: translateY(-50%);
  width: 20px;
  padding: 60px 0;
  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #2d2314;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}

.top-fv__menu__label {
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #2d2314;
}

.top-fv__menu__list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
