.page__heading {
  background-color: #f5f1ee;
  padding-top: 172px;
  @include mq(u-sp) {
    padding: 124px 15px 0;
  }
}

.page__heading__inner {
  text-align: center;
  & > h1 {
    font-family: "montserrat";
    font-size: 80px;
    line-height: 100%;
    letter-spacing: 0.12em;
    color: #2d2314;
  }
  & > p {
    margin-top: 20px;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #b8a396;
  }
  & > div {
    text-align: center;
    font-size: 14px;
    opacity: 0.8;
    margin-top: 40px;
    word-break: break-word;
    & > span {
      display: inline-block;
      line-height: 160%;
    }
  }
  @include mq(u-sp) {
    & > h1 {
      font-size: 40px;
    }
    & > p {
      margin-top: 12px;
      font-size: 14px;
      line-height: 100%;
    }
  }
}

.contact {
  background-color: #f5f1ee;
  margin-top: 160px;
  @include mq(u-tab) {
    padding: 0 40px;
  }
  @include mq(u-sp) {
    padding: 0 15px;
    margin-top: 60px;
  }
}

.contact__board {
  background-color: #39433a;
  max-width: 984px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 80px 140px;
  position: relative;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.25));
  border-radius: 6px;
  color: #fffffa;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url("../img/texture/img_texture_contactform.png");
    background-size: 200px;
    background-repeat: repeat;
    opacity: 1;
    transition: 0.2s;
    opacity: 0.5;
  }
  & > p {
    text-align: center;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.05em;
  }
  @include mq(u-tab) {
    padding: 40px 40px 100px;
    & > p {
      text-align: left;
    }
  }
  @include mq(u-sp) {
    padding: 20px 15px 100px;
    & > p {
      font-size: 14px;
    }
  }
}

.contact__board__thx {
  @extend .contact__board;
  padding: 304px 0;
  min-height: 984px;
  @include mq(u-sp) {
    padding: 120px 15px;
    min-height: 0;
  }
}

.contact__board__thx__bg__top,
.contact__board__thx__bg__bottom {
  position: absolute;
  width: 100%;
  left: 0;
}

.contact__board__thx__bg__top {
  top: 0;
}

.contact__board__thx__bg__bottom {
  bottom: 0;
}

.contact__board__thx__content {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  @include mq(u-sp) {
    position: relative;
    transform: unset;
  }
}

.contact__board__thx__headline {
  width: 406px;
  margin: 0 auto;
  @include mq(u-sp) {
    width: 244px;
  }
}
.contact__board__thx__text {
  margin-top: 80px;
  font-weight: bold;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 0.05em;
  color: #d68622;
  @include mq(u-sp) {
    margin-top: 40px;
    font-size: 14px;
    line-height: 100%;
  }
}

.contact__board__thx__message {
  margin-top: 40px;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #fffffa;
  @include mq(u-sp) {
    margin-top: 20px;
    font-size: 14px;
  }
}

.contact__board__rope__left,
.contact__board__rope__right {
  position: absolute;
  top: 0;
  width: 5px;
  height: 622px;
  background-color: #000;
  z-index: 1;
  transform: translateY(-100%);
}

.contact__board__rope__left {
  left: 120px;
  @include mq(u-sp) {
    left: 30px;
  }
}

.contact__board__rope__right {
  right: 120px;
  @include mq(u-sp) {
    right: 30px;
  }
}

.page__link {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 20px;
  &:hover {
    & .page__link__icon circle {
      animation: circle 1s;
      opacity: 1;
    }
  }
  @include mq(u-sp) {
    gap: 12px;
  }
}

.page__link__title {
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #2d2314;
}

.page__link__icon {
  width: 80px;
  height: 80px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 8px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2222%22%20height%3D%2210%22%20viewBox%3D%220%200%2022%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%205.95484H21L13.3944%201V9%22%20stroke%3D%22%232D2314%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
    top: 50%;
    left: 50%;
    background-size: cover;
    transform: translate(-50%, -50%);
  }
  &::before {
    position: absolute;
    content: "";
    width: 80px;
    height: 80px;
    top: 0;
    left: 0;
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20fill%3D%22none%22%20height%3D%2280%22%20viewBox%3D%220%200%2080%2080%22%20width%3D%2280%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Ccircle%20cx%3D%2240%22%20cy%3D%2240%22%20opacity%3D%220.3%22%20r%3D%2239.5%22%20stroke%3D%22%232d2314%22%20%2F%3E%20%3C%2Fsvg%3E");
  }
  & svg {
    transform: rotate(-90deg);
  }
  & circle {
    fill: transparent;
    stroke: #2d2314;
    stroke-width: 1;
  }
  @include mq(u-tab) {
    &::before {
      display: none;
    }
    & circle {
      opacity: 1;
    }
  }
  @include mq(u-sp) {
    width: 40px;
    height: 40px;
    & svg {
      width: 40px;
      height: 40px;
    }
  }
}
