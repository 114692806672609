.top-contact {
  color: #fffffa;
  margin-left: auto;
  transition: background-color 0.2s;
  position: relative;
  z-index: 2;
  background-color: #b8a396;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url("../img/texture/img_texture_about_contact.png");
    background-size: 200px;
    background-repeat: repeat;
    opacity: 1;
    transition: 0.2s;
    opacity: 0.5;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    background: linear-gradient(93.01deg, #e2af89 0%, #b8a396 100%);
    opacity: 0;
    transition: 0.2s;
  }
  &:hover {
    &::after {
      opacity: 1;
    }
    & .contact-link__icon circle {
      animation: circle 1s;
      opacity: 1;
    }
  }
  @include mq(u-sp) {
    & .contact__banner__guide {
      display: inline-block;
      text-align: center;
      margin-top: 40px;
    }
  }
}
