.header {
  width: 100%;
  padding: 40px 0;
  position: absolute;
  top: 0;
  left: 0;
}

.header__logo {
  display: block;
  width: 257px;
  margin: 0 auto;
  // opacity: 0;
  @include mq(u-tab) {
    width: 196px;
  }
}
