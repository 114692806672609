.top-schedule {
  max-width: 984px;
  margin: 200px auto 0;
  @include mq(u-tab) {
    padding: 0 40px;
    margin: 200px 0 0;
  }
  @include mq(u-sp) {
    padding: 0 15px;
    margin: 80px 0 0;
  }
}

.top-schedule__heading {
  font-family: "montserrat";
  font-weight: bold;
  font-size: 56px;
  line-height: 100%;
  letter-spacing: 0.12em;
  color: #2d2314;
  text-align: center;
  @include mq(u-sp) {
    font-size: 32px;
  }
}
.top-schedule__translate {
  margin-top: 20px;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #b8a396;
  text-align: center;
  @include mq(u-sp) {
    margin-top: 8px;
  }
}

.top-schedule-grid {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @include mq(u-sp) {
    margin-top: 40px;
    gap: 20px;
  }
}

.top-schedule__card {
  padding: 40px 40px 20px;
  width: calc(50% - 10px);
  background-color: #fffffa;
  position: relative;
  box-sizing: border-box;
  &.hwg {
    color: #694605;
    border: 8px solid #694605;
    & .top-schedule__card__category::after {
      background-color: #694605;
    }
    & .top-schedule__card__decoration {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      & > .top-schedule__card__decoration--left,
      & > .top-schedule__card__decoration--right {
        position: absolute;
        height: 100%;
      }
      & > .top-schedule__card__decoration--left {
        left: 12px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22white%22%2F%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23694605%22%2F%3E%3Crect%20x%3D%226%22%20y%3D%226%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22%23E99E40%22%2F%3E%3C%2Fsvg%3E");
          left: -20px;
        }
        &::before {
          top: -8px;
        }
        &::after {
          bottom: -8px;
        }
      }
      & > .top-schedule__card__decoration--right {
        right: 12px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22white%22%2F%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23694605%22%2F%3E%3Crect%20x%3D%226%22%20y%3D%226%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22%23E99E40%22%2F%3E%3C%2Fsvg%3E");
          right: -20px;
        }
        &::before {
          top: -8px;
        }
        &::after {
          bottom: -8px;
        }
      }
    }
  }
  &.meet-up {
    color: #3c699d;
    border: 4px solid #d55636;
    & .top-schedule__card__category::after {
      background-color: #3c699d;
    }
    & .top-schedule__card__decoration {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 6px solid #3c699d;
      & > .top-schedule__card__decoration--left,
      & > .top-schedule__card__decoration--right {
      }
      & > .top-schedule__card__decoration--left {
      }
      & > .top-schedule__card__decoration--right {
      }
    }
  }
  &.special-event {
    color: #694605;
    border: 6px solid #694605;
    & .top-schedule__card__category::after {
      background-color: #694605;
    }
    & .top-schedule__card__decoration {
      position: absolute;
      top: 0;
      left: 0;
      margin: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        border: 3px solid #3c699d;
        z-index: 2;
      }
      & > .top-schedule__card__decoration--left,
      & > .top-schedule__card__decoration--right {
        position: absolute;
        height: 100%;
        z-index: 1;
      }
      & > .top-schedule__card__decoration--left {
        left: 15px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 44px;
          height: 44px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2244%22%20height%3D%2244%22%20viewBox%3D%220%200%2044%2044%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url(%23clip0_120_35)%22%3E%3Crect%20x%3D%223%22%20y%3D%223%22%20width%3D%22354%22%20height%3D%22234%22%20fill%3D%22%23FFFFFA%22%20stroke%3D%22%23694605%22%20stroke-width%3D%226%22%2F%3E%3Cpath%20d%3D%22M0.000488281%2040C22.0919%2040%2040.0005%2022.0914%2040.0005%200L0.000488281%200L0.000488281%2040Z%22%20fill%3D%22%23F5F1EE%22%2F%3E%3Cpath%20d%3D%22M40%200C40%2013.5%2029.6%2040%200%2040%22%20stroke%3D%22%23694605%22%20stroke-width%3D%226%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_120_35%22%3E%3Crect%20width%3D%2244%22%20height%3D%2244%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
          background-size: cover;
          left: -27px;
        }
        &::before {
          top: -12px;
        }
        &::after {
          bottom: -12px;
          transform: rotate(-90deg);
        }
      }
      & > .top-schedule__card__decoration--right {
        right: 15px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 44px;
          height: 44px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2244%22%20height%3D%2244%22%20viewBox%3D%220%200%2044%2044%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url(%23clip0_120_35)%22%3E%3Crect%20x%3D%223%22%20y%3D%223%22%20width%3D%22354%22%20height%3D%22234%22%20fill%3D%22%23FFFFFA%22%20stroke%3D%22%23694605%22%20stroke-width%3D%226%22%2F%3E%3Cpath%20d%3D%22M0.000488281%2040C22.0919%2040%2040.0005%2022.0914%2040.0005%200L0.000488281%200L0.000488281%2040Z%22%20fill%3D%22%23F5F1EE%22%2F%3E%3Cpath%20d%3D%22M40%200C40%2013.5%2029.6%2040%200%2040%22%20stroke%3D%22%23694605%22%20stroke-width%3D%226%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_120_35%22%3E%3Crect%20width%3D%2244%22%20height%3D%2244%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
          right: -27px;
        }
        &::before {
          top: -12px;
          transform: rotate(90deg);
        }
        &::after {
          bottom: -12px;
          transform: rotate(180deg);
        }
      }
    }
  }
  &.after-school {
    color: #e99e40;
    border: 6px solid #e99e40;
    & .top-schedule__card__category::after {
      background-color: #e99e40;
    }
    & .top-schedule__card__decoration {
      position: absolute;
      height: calc(100% - 10px);
      width: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      & > .top-schedule__card__decoration--left,
      & > .top-schedule__card__decoration--right {
        position: absolute;
        height: calc(100% - 10px);
        width: 6px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%22258%22%20viewBox%3D%220%200%206%20258%22%20width%3D%226%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20d%3D%22m0%200h6v258h-6z%22%2F%3E%3C%2FclipPath%3E%3Cg%20clip-path%3D%22url(%23a)%22%3E%3Cpath%20d%3D%22m3-6%20.00001%20296%22%20stroke%3D%22%23e99e40%22%20stroke-dasharray%3D%224%204%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%226%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
        top: 50%;
        transform: translateY(-50%);
      }
      & > .top-schedule__card__decoration--left {
        left: 18px;
      }
      & > .top-schedule__card__decoration--right {
        right: 18px;
      }
    }
  }
  &.open-to-all {
    color: #d55636;
    border: 6px solid #d55636;
    & .top-schedule__card__category::after {
      background-color: #d55636;
    }
    & .top-schedule__card__decoration {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      & > .top-schedule__card__decoration--left,
      & > .top-schedule__card__decoration--right {
        position: absolute;
        height: calc(100% - 70px);
        width: 4px;
        background-color: #d55636;
        top: 50%;
        transform: translateY(-50%);
      }
      & > .top-schedule__card__decoration--left {
        left: 18px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-color: #d55636;
          left: -20px;
        }
        &::before {
          top: -40px;
        }
        &::after {
          bottom: -40px;
        }
      }
      & > .top-schedule__card__decoration--right {
        right: 18px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-color: #d55636;
          right: -20px;
        }
        &::before {
          top: -40px;
        }
        &::after {
          bottom: -40px;
        }
      }
    }
  }
  @include mq(u-sp) {
    width: 100%;
  }
}

.top-schedule__card__category {
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.05em;
  padding-bottom: 10px;
  position: relative;
  text-align: center;
  &::after {
    position: absolute;
    content: "";
    height: 1px;
    bottom: 0;
    background-color: #2d2314;
    width: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.top-schedule__card__title {
  margin-top: 22px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
}

.top-schedule__card__flex {
  display: flex;
  gap: 6px;
  padding-left: 8px;
  font-family: "helvetica";
  align-items: baseline;
  margin-top: 22px;
}

.top-schedule__card__date {
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
}

.top-schedule__card__week {
  font-size: 12px;
  line-height: 100%;
}

.top-schedule__card__inner {
  position: relative;
  z-index: 2;
}
.top-schedule__link__wrap {
  margin-top: 80px;
  width: 100%;
  @include mq(u-sp) {
    margin: 40px auto 0;
  }
}
