.contact__form {
  margin-top: 62px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  & .wpcf7-spinner {
    display: none;
  }
  @include mq(u-sp) {
    margin-top: 42px;
  }
}

.contact__form__input {
  width: calc(50% - 20px);
  & label {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #fffffa;
    font-weight: bold;
  }
  & input {
    color: #fffffa;
    margin-top: 14px;
    padding: 8px 16px 18px;
    border-bottom: 2px solid #fffffa;
    &.wpcf7-not-valid {
      border-bottom: 2px solid #d68622;
    }
  }
  & .wpcf7-not-valid-tip {
    margin-top: 12px;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #d68622;
  }
  @include mq(u-tab) {
    width: 100%;
    & input {
      width: 100%;
    }
  }
  @include mq(u-sp) {
    & input {
      margin-top: 8px;
      padding: 8px 12px 16px;
      font-size: 14px;
    }
    & .wpcf7-not-valid-tip {
      margin-top: 8px;
    }
  }
}

.contact__form__textarea {
  width: 100%;
  & label {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #fffffa;
    font-weight: bold;
  }
  & textarea {
    width: 100%;
    color: #fffffa;
    margin-top: 14px;
    padding: 12px;
    border: 2px solid #fffffa;
    box-sizing: border-box;
    border-radius: 4px;
    &.wpcf7-not-valid {
      border: 2px solid #d68622;
    }
  }
  & .wpcf7-not-valid-tip {
    margin-top: 12px;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #d68622;
  }
  @include mq(u-sp) {
    & textarea {
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.05em;
    }
    & .wpcf7-not-valid-tip {
      margin-top: 2px;
    }
  }
}

.contact__form__policy {
}

.wpcf7-response-output {
  display: none;
}
.contact__form__policy {
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #fffffa;
  font-weight: bold;
  & > p:last-child {
    margin-top: 24px;
    text-align: center;
    & .wpcf7-list-item-label {
      cursor: pointer;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.05em;
      color: #fffffa;
      padding-left: 36px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 24px;
        height: 24px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid #fffffa;
        box-sizing: border-box;
        border-radius: 2px;
      }
      &::after {
        position: absolute;
        content: "";
        width: 15px;
        height: 11px;
        background-image: url("../img/contact/img_check.svg");
        background-size: cover;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
      }
    }
    @include mq(u-tab) {
      & .wpcf7-list-item-label {
        display: inline-block;
        line-height: 160%;
      }
    }
    @include mq(u-sp) {
      text-align: left;
      & .wpcf7-list-item-label {
        font-size: 14px;
        line-height: 180%;
        font-weight: normal;
      }
    }
  }
}

input[type="checkbox"]:checked + .wpcf7-list-item-label::after {
  opacity: 1;
}

.contact__form__policy__text {
  font-weight: normal;
  width: 100%;
  color: #fffffa;
  margin-top: 14px;
  padding: 12px;
  border: 2px solid #fffffa;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.05em;
  @include mq(u-sp) {
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.05em;
  }
}

.contact__form__policy__text {
  margin-top: 12px;
  padding: 12px;
}

div.contact__form__send {
  margin: 44px auto 0;
  position: relative;
  & svg {
    position: absolute;
    width: 225px;
    height: 110px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../img/contact/img_send.svg");
    background-size: cover;
  }
  & path {
    fill: transparent;
    stroke: #fffffa;
    opacity: 0;
  }
  &:hover {
    & path {
      animation: sendline 0.4s;
      opacity: 1;
    }
  }
  @include mq(u-tab) {
    & path {
      animation: sendlineStatic 0.1s;
      opacity: 1;
    }
  }
}

input.contact__form__send {
  cursor: pointer;
  position: relative;
  z-index: 2;
  padding: 10px 56px 10px 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: #fffffa;
}

.contact__banner {
  display: block;
  position: relative;
  z-index: 2;
  padding: 120px 0;
  background-color: #b8a396;
  margin-top: 210px;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url("../img/texture/img_texture_about_contact.png");
    background-size: 200px;
    background-repeat: repeat;
    opacity: 1;
    transition: 0.2s;
    opacity: 0.5;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    background: linear-gradient(93.01deg, #e2af89 0%, #b8a396 100%);
    opacity: 0;
    transition: 0.2s;
  }
  &:hover {
    &::after {
      opacity: 1;
    }
    & .contact__banner__icon circle {
      animation: circle 1s;
      opacity: 1;
    }
  }
  @include mq(u-tab) {
    padding: 80px 40px;
    margin-top: 136px;
  }
  @include mq(u-sp) {
    padding: 80px 15px;
    margin-top: 180px;
  }
}

.contact__banner__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1144px;
  margin: 0 auto;
  padding: 0 80px;
  color: #fffffa;
  @include mq(u-tab) {
    padding: 0;
    gap: 20px;
  }
  @include mq(u-sp) {
    flex-direction: column;
    gap: 40px;
  }
}

.contact__banner__content__headline {
  font-family: "montserrat";
  font-size: 56px;
  line-height: 100%;
  letter-spacing: 0.12em;
  @include mq(u-sp) {
    text-align: center;
    font-size: 32px;
  }
}

.contact__banner__content__translate {
  margin-top: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  @include mq(u-sp) {
    text-align: center;
    margin-top: 8px;
    font-size: 12px;
  }
}

.contact__banner__content__description {
  margin-top: 60px;
  @include mq(u-tab) {
    margin-top: 40px;
  }
  @include mq(u-sp) {
    text-align: center;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.05em;
  }
}

.contact__banner__icon {
  width: 200px;
  height: 200px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 60px;
    height: 24px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2262%22%20height%3D%2226%22%20viewBox%3D%220%200%2062%2026%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%2015.8645H61L38.1831%201V25%22%20stroke%3D%22%23FFFFFA%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf8,%20%3Csvg%20width%3D%22200%22%20height%3D%22200%22%20viewBox%3D%220%200%20200%20200%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Ccircle%20opacity%3D%220.4%22%20cx%3D%22100%22%20cy%3D%22100%22%20r%3D%2299.5%22%20stroke%3D%22%23FFFFFA%22%20%2F%3E%20%3C%2Fsvg%3E");
    top: 0;
    left: 0;
  }
  & svg {
    transform: rotate(-90deg);
  }
  & circle {
    fill: transparent;
    stroke: #fffffa;
    stroke-width: 1;
  }
  @include mq(u-tab) {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    & svg {
      display: none;
    }
    &::after {
      width: 40px;
      height: 16px;
      background-size: cover;
    }
    &::before {
      width: 100%;
      height: 100%;
      background-size: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%22200%22%20height%3D%22200%22%20viewBox%3D%220%200%20200%20200%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Ccircle%20opacity%3D%221%22%20cx%3D%22100%22%20cy%3D%22100%22%20r%3D%2299.5%22%20stroke%3D%22%23FFFFFA%22%3E%3C%2Fcircle%3E%20%3C%2Fsvg%3E");
    }
  }
}

.contact__banner__guide {
  padding-right: 52px;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20stroke%3D%22%23fffffa%22%3E%3Cpath%20d%3D%22m12%2020.7161h15l-5.7042-3.7161v6%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3Ccircle%20cx%3D%2220%22%20cy%3D%2220%22%20r%3D%2219.5%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.contact-link__icon {
  margin-top: 60px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 40px;
    height: 16px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2262%22%20height%3D%2226%22%20viewBox%3D%220%200%2062%2026%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%2015.8645H61L38.1831%201V25%22%20stroke%3D%22%23FFFFFA%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
    background-size: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  & svg {
    transform: rotate(-90deg);
  }
  & circle {
    fill: transparent;
    stroke: #fffffa;
    stroke-width: 1;
  }
  @include mq(u-tab) {
    & circle {
      opacity: 1;
    }
  }
}
