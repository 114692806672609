.event {
  @include mq(u-tab) {
    padding: 0 40px;
  }
  @include mq(u-sp) {
    padding: 0 15px;
  }
}

.event__current {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 100px;
  @include mq(u-sp) {
    margin-top: 80px;
  }
}

.event__current__year {
  font-family: "Helvetica";
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.05em;
  color: #2d2314;
  @include mq(u-sp) {
    font-size: 24px;
  }
}

.event__current__month {
  font-family: "Helvetica";
  font-weight: bold;
  font-size: 120px;
  line-height: 100%;
  color: #2d2314;
  margin-top: 24px;
  @include mq(u-sp) {
    font-size: 80px;
    margin-top: 10px;
  }
}

.event__controller {
  width: 984px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @include mq(u-tab) {
    width: 100%;
  }
}

.event__controller__bottom {
  @extend .event__controller;
  margin-top: 90px;
  @include mq(u-tab) {
    padding: 0 40px;
  }
  @include mq(u-sp) {
    padding: 0 15px;
  }
}

.event__content {
  width: 984px;
  margin: 40px auto 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @include mq(u-tab) {
    width: 100%;
  }
  @include mq(u-sp) {
    gap: 20px;
    margin-top: 22px;
  }
}

.event__controller__prev,
.event__controller__next {
  font-family: "montserrat";
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.1em;
  position: relative;
}

.event__controller__prev {
  padding-left: 60px;
  margin-right: auto;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 40px;
    height: 16px;
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%2217%22%20viewBox%3D%220%200%2042%2017%22%20width%3D%2242%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m40.877%209.90968c.2761%200%20.5.22382.5.50002%200%20.2761-.2239.5-.5.5zm-40.000047.50002v.5c-.221313%200-.416298-.1455-.479305-.3577-.063011-.2121.020943-.4405.206379-.56126zm15.211247-9.9097-.2729-.4189402c.1537-.1001356.3499-.1080579.5112-.0206411.1613.0874163.2617.2561333.2617.4395813zm.5%2016c0%20.2761-.2238.5-.5.5-.2761%200-.5-.2239-.5-.5zm24.2888-5.5903h-40.000047v-1.00002h40.000047zm-40.272973-.91896%2015.211273-9.9096802.5458.8378802-15.21122%209.90966zm15.984173-9.49074v16h-1v-16z%22%20fill%3D%22%232d2314%22%2F%3E%3C%2Fsvg%3E");
    transition: left 0.2s;
  }
  &:hover {
    &:before {
      left: -10px;
    }
  }
}

.event__controller__next {
  padding-right: 60px;
  margin-left: auto;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 40px;
    height: 16px;
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%2218%22%20viewBox%3D%220%200%2042%2018%22%20width%3D%2242%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m.876953%2010.4097c-.276142%200-.5.2238-.5.5%200%20.2761.223858.5.5.5zm40.000047.5v.5c.2213%200%20.4163-.1455.4793-.3577.063-.2121-.021-.4405-.2064-.5613zm-15.2113-9.9097.2729-.41894c-.1537-.100136-.3499-.108058-.5112-.020641-.1613.087416-.2617.256133-.2617.439581zm-.5%2016c0%20.2761.2238.5.5.5.2761%200%20.5-.2239.5-.5zm-24.288747-5.5903h40.000047v-1h-40.000047zm40.272947-.919-15.2113-9.90964-.5458.83788%2015.2112%209.90966zm-15.9842-9.4907v16h1v-16z%22%20fill%3D%22%232d2314%22%2F%3E%3C%2Fsvg%3E");
    transition: right 0.2s;
  }
  &:hover {
    &::after {
      right: -10px;
    }
  }
}

.event__content__item {
  padding: 40px 100px;
  background-color: #fffffa;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  @include mq(u-tab) {
    padding: 40px 40px 40px 80px;
  }
  @include mq(u-sp) {
    padding: 40px;
  }

  & .event__content__item__decoration {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  &.hwg {
    border: 8px solid #694605;
    & .event__content__item__category,
    & .event__content__item__details__heading,
    & .event__content__item__details__heading {
      color: #694605;
    }
    & .event__content__item__category::after {
      background-color: #694605;
    }
    & .event__content__item__decoration {
      padding: 8px;
      border: 3px solid #e99e40;
      box-sizing: border-box;
      & > .event__content__item__decoration--left,
      & > .event__content__item__decoration--right {
        position: absolute;
        height: 100%;
      }
      & > .event__content__item__decoration--left {
        left: 16px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22white%22%2F%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23694605%22%2F%3E%3Crect%20x%3D%226%22%20y%3D%226%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22%23E99E40%22%2F%3E%3C%2Fsvg%3E");
          left: -27px;
        }
        &::before {
          top: -19px;
        }
        &::after {
          bottom: -3px;
        }
      }
      & > .event__content__item__decoration--right {
        right: 16px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22white%22%2F%3E%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23694605%22%2F%3E%3Crect%20x%3D%226%22%20y%3D%226%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22%23E99E40%22%2F%3E%3C%2Fsvg%3E");
          right: -27px;
        }
        &::before {
          top: -19px;
        }
        &::after {
          bottom: -3px;
        }
      }
    }
  }
  &.meet-up {
    border: 4px solid #d55636;
    & .event__content__item__category,
    & .event__content__item__details__heading,
    & .event__content__item__details__heading {
      color: #3c699d;
    }
    & .event__content__item__category::after {
      background-color: #3c699d;
    }
    & .event__content__item__decoration {
      padding: 4px;
      border: 6px solid #3c699d;
      box-sizing: border-box;
    }
  }
  &.special-event {
    border: 6px solid #694605;
    & .event__content__item__category,
    & .event__content__item__details__heading,
    & .event__content__item__details__heading {
      color: #694605;
    }
    & .event__content__item__category::after {
      background-color: #694605;
    }
    & .event__content__item__decoration {
      margin: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        border: 3px solid #3c699d;
        z-index: 2;
      }
      & > .event__content__item__decoration--left,
      & > .event__content__item__decoration--right {
        position: absolute;
        height: 100%;
        z-index: 1;
      }
      & > .event__content__item__decoration--left {
        left: 15px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 44px;
          height: 44px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2244%22%20height%3D%2244%22%20viewBox%3D%220%200%2044%2044%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url(%23clip0_120_35)%22%3E%3Crect%20x%3D%223%22%20y%3D%223%22%20width%3D%22354%22%20height%3D%22234%22%20fill%3D%22%23FFFFFA%22%20stroke%3D%22%23694605%22%20stroke-width%3D%226%22%2F%3E%3Cpath%20d%3D%22M0.000488281%2040C22.0919%2040%2040.0005%2022.0914%2040.0005%200L0.000488281%200L0.000488281%2040Z%22%20fill%3D%22%23F5F1EE%22%2F%3E%3Cpath%20d%3D%22M40%200C40%2013.5%2029.6%2040%200%2040%22%20stroke%3D%22%23694605%22%20stroke-width%3D%226%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_120_35%22%3E%3Crect%20width%3D%2244%22%20height%3D%2244%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
          background-size: cover;
          left: -27px;
        }
        &::before {
          top: -12px;
        }
        &::after {
          bottom: -12px;
          transform: rotate(-90deg);
        }
      }
      & > .event__content__item__decoration--right {
        right: 15px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 44px;
          height: 44px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2244%22%20height%3D%2244%22%20viewBox%3D%220%200%2044%2044%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url(%23clip0_120_35)%22%3E%3Crect%20x%3D%223%22%20y%3D%223%22%20width%3D%22354%22%20height%3D%22234%22%20fill%3D%22%23FFFFFA%22%20stroke%3D%22%23694605%22%20stroke-width%3D%226%22%2F%3E%3Cpath%20d%3D%22M0.000488281%2040C22.0919%2040%2040.0005%2022.0914%2040.0005%200L0.000488281%200L0.000488281%2040Z%22%20fill%3D%22%23F5F1EE%22%2F%3E%3Cpath%20d%3D%22M40%200C40%2013.5%2029.6%2040%200%2040%22%20stroke%3D%22%23694605%22%20stroke-width%3D%226%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_120_35%22%3E%3Crect%20width%3D%2244%22%20height%3D%2244%22%20fill%3D%22white%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
          right: -27px;
        }
        &::before {
          top: -12px;
          transform: rotate(90deg);
        }
        &::after {
          bottom: -12px;
          transform: rotate(180deg);
        }
      }
    }
  }
  &.after-school {
    border: 6px solid #e99e40;
    & .event__content__item__category,
    & .event__content__item__details__heading,
    & .event__content__item__details__heading {
      color: #e99e40;
    }
    & .event__content__item__category::after {
      background-color: #e99e40;
    }
    & .event__content__item__decoration {
      margin: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      & > .event__content__item__decoration--left,
      & > .event__content__item__decoration--right {
        position: absolute;
        height: calc(100% - 10px);
        width: 6px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%22258%22%20viewBox%3D%220%200%206%20258%22%20width%3D%226%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20d%3D%22m0%200h6v258h-6z%22%2F%3E%3C%2FclipPath%3E%3Cg%20clip-path%3D%22url(%23a)%22%3E%3Cpath%20d%3D%22m3-6%20.00001%20296%22%20stroke%3D%22%23e99e40%22%20stroke-dasharray%3D%224%204%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%226%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
        top: 50%;
        transform: translateY(-50%);
      }
      & > .event__content__item__decoration--left {
        left: 0;
      }
      & > .event__content__item__decoration--right {
        right: 0;
      }
    }
  }
  &.open-to-all {
    border: 6px solid #d55636;
    & .event__content__item__category,
    & .event__content__item__details__heading,
    & .event__content__item__details__heading {
      color: #d55636;
    }
    & .event__content__item__category::after {
      background-color: #d55636;
    }
    & .event__content__item__decoration {
      padding: 6px;
      & > .event__content__item__decoration--left,
      & > .event__content__item__decoration--right {
        position: absolute;
        height: calc(100% - 64px);
        width: 4px;
        background-color: #d55636;
        top: 50%;
        transform: translateY(-50%);
      }
      & > .event__content__item__decoration--left {
        left: 16px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-color: #d55636;
          left: -20px;
        }
        &::before {
          top: -33px;
        }
        &::after {
          bottom: -33px;
        }
      }
      & > .event__content__item__decoration--right {
        right: 16px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-color: #d55636;
          right: -20px;
        }
        &::before {
          top: -33px;
        }
        &::after {
          bottom: -33px;
        }
      }
    }
  }
}

.event__content__item__inner {
  display: flex;
  gap: 120px;
  position: relative;
  z-index: 2;
  @include mq(u-sp) {
    flex-direction: column;
    gap: 32px;
  }
}

.event__content__item__date {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @include mq(u-sp) {
    flex-direction: row-reverse;
    gap: 8px;
    justify-content: center;
  }
}

.event__content__item__week,
.event__content__item__week__sp {
  font-family: "helvetica";
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.2em;
  color: #2d2314;
}

.event__content__item__week__sp {
  text-align: center;
  writing-mode: vertical-lr;
  text-align: left;
  padding-top: 3px;
}

.event__content__item__month-day {
  display: flex;
  align-items: center;
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-family: "helvetica";
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  color: #2d2314;
  & span {
    writing-mode: horizontal-tb;
    &.day {
      font-weight: bold;
      font-size: 60px;
      line-height: 100%;
    }
  }
  @include mq(u-sp) {
    writing-mode: unset;
    align-items: baseline;
    font-size: 24px;
    line-height: 130%;
    & span {
      &.day {
        font-size: 40px;
      }
    }
  }
}

.event__content__item__content {
  @include mq(u-sp) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.event__content__item__category {
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.05em;
  padding-bottom: 10px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 16px;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #2d2314;
  }
  @include mq(u-sp) {
    &::after {
      width: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.event__content__item__title {
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: #2d2314;
  @include mq(u-sp) {
    text-align: center;
  }
}

* + .event__content__item__title {
  margin-top: 16px;
  @include mq(u-sp) {
    margin-top: 12px;
  }
}

.event__content__item__actors {
  margin-top: 32px;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.05em;
  color: #3c2f2f;
  display: flex;
  flex-wrap: wrap;

  & > span {
    position: relative;
    padding-right: 4px;
    &:not(:last-child):after {
      content: " /";
      right: 0;
      top: 0;
    }
  }
  @include mq(u-sp) {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 14px;
    line-height: 140%;
  }
}

.event__content__item__details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
}

.event__content__item__details__heading {
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.05em;
  min-width: 38px;
  @include mq(u-sp) {
    text-align: center;
  }
}

.event__content__item__tickets {
  @include mq(u-tab) {
    flex-shrink: 0;
  }
}
.event__content__item__tickets__list {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 160%;
  @include mq(u-sp) {
    text-align: center;
    font-size: 14px;
    // line-height: 100%;
  }
}

.event__content__item__schedule {
  display: flex;
  gap: 4px 40px;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  @include mq(u-sp) {
    justify-content: center;
    gap: 4px 20px;
  }
}

.event__content__item__schedule__item {
  display: flex;
  gap: 4px 14px;
  align-items: center;
  & > p {
    line-height: 160%;
  }
  @include mq(u-sp) {
    font-size: 14px;
    gap: 8px;
  }
}

.event__past__archive {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: -100%;
  transform: translateX(-50%);
  z-index: 3;
  @include mq(u-sp) {
    top: calc(100% + 42px);
    display: block;
    width: 100%;
    padding: 0 15px;
  }
}

.event__past__archive__btn {
  padding: 17px 22px;
  display: inline-block;
  border: 1px solid #b8a396;
  box-sizing: border-box;
  cursor: pointer;
  @include mq(u-sp) {
    display: block;
    text-align: center;
  }
}

.event__past__archive__title {
  padding-right: 30px;
  position: relative;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #2d2314;
  &::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 5px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2211%22%20height%3D%226%22%20viewBox%3D%220%200%2011%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.5%201L5.5%205L10.5%201%22%20stroke%3D%22%232D2314%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
    background-size: cover;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  @include mq(u-sp) {
    display: inline-block;
  }
}

.event__past__archive__list {
  max-height: 230px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  display: none;
  background-color: #fffffa;
  & > li {
    padding: 12px 40px;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.05em;
  }
}
