@font-face {
  font-family: "montserrat";
  font-weight: medium;
  src: url("../fonts/Montserrat-Medium.woff");
}

@font-face {
  font-family: "notosans";
  font-weight: medium;
  src: url("../fonts/NotoSansJP-Regular.woff");
}

body {
  font-family: "notosans";
  // font-family: "NotoSansJP", "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体",
  //   "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif; /* 游ゴシック系 */
  //  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;  /* 游明朝系 */
  //  font-family: Hiragino Sans,"ヒラギノ角ゴシック",Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, "Droid Sans", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;  /* ヒラギノ角ゴ系 */
}
