.top-grid {
  display: flex;
  margin-top: 200px;
  @include mq(u-tab) {
    flex-direction: column;
  }
  @include mq(u-sp) {
    margin-top: 120px;
  }
}

.top-grid__item {
  width: 50%;
  padding: 160px 0;
  @include mq(u-tab) {
    width: 100%;
    padding: 80px;
  }
  @include mq(u-sp) {
    padding: 80px 15px;
  }
}

.top-grid__item__inner {
  padding: 0 100px;
  text-align: center;
  @include mq(u-tab) {
    padding: 0;
  }
}

.top-grid__item__heading {
  font-size: 56px;
  line-height: 100%;
  letter-spacing: 0.12em;
  font-family: "montserrat";
  @include mq(u-sp) {
    font-size: 32px;
  }
}

.top-grid__item__translate {
  margin-top: 20px;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.05em;
  @include mq(u-sp) {
    margin-top: 8px;
    font-size: 12px;
  }
}

.top-grid__item__text {
  margin-top: 60px;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.05em;
  @include mq(u-sp) {
    margin-top: 40px;
    font-size: 14px;
  }
}
